import React from "react";
import cloudConsulting from "../../images/cloud-consulting.png";
import s1 from "../../images/s1.svg";
import s2 from "../../images/s2.svg";
import s3 from "../../images/s3.svg";
import s4 from "../../images/s4.svg";
import s5 from "../../images/s5.svg";
import s6 from "../../images/s6.svg";
import s7 from "../../images/s7.svg";
import s8 from "../../images/s8.svg";
import s9 from "../../images/s9.svg";
import mig from "../../images/cloudmig.svg";
import opr from "../../images/cloudopr.svg";
import { Link } from "gatsby";
var services = [mig, s4, s5, s6, s3, opr, s1, s8, s7, s2, s9];

function Solutions({ data, locale }) {
  return (
    <div className="solSection">
      <div className="solWrap">
        <div className="solsecHead">
          <h4 className="smallHeading">{data.title}</h4>
          <h2 className="secHeading">
            {data.heading1} <span>{data.heading2}</span>
          </h2>
        </div>
        <div className="solsecBody">
          <div className="solbodyWrap">
            <div className="solutioncol">
              {data.solutionsList.map((solution, index) => {
                return (
                  <div className="solcolWrap" key={index}>
                    <div className="outside_wrap">
                      <div className="solImgwrap">
                        <img
                          src={services[index]}
                          alt="solution"
                          className="solutionImg"
                        />
                      </div>
                      <Link
                        to={`/${solution.src}/`}
                        style={{ textDecoration: "none" }}
                      >
                        <span className="solName">{solution.name}</span>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="enqCta">
        <div className="enqCtaWrap">
          <div className="enqCtacol">
            <div className="enqCtacol-left">
              <h6>{data.CTA.heading}</h6>
              <p style={{ color: "white" }}>{data.CTA.des}</p>
            </div>
            <div className="opsBtnwrap">
              <Link to="#enquire" className="opsBtnlink">
                {data.CTA.btn}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Solutions;
