import React from "react";
//import banner3 from "../../images/banner3.jpg"
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { navigate } from "gatsby";
// import banner1 from "../../images/banner1.jpeg"
const HomepageBlog = ({ locale }) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiBlogs {
        edges {
          node {
            locale
            slug
            title
            heading
            preview
            id
            blogimg {
              localFile {
                childImageSharp {
                  fixed {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
      allStrapiDataCommonBlogSection {
        edges {
          node {
            locale
            btn
            heading
            title
          }
        }
      }
    }
  `);
  const AllStrapiCommonBlogSections = data.allStrapiBlogs.edges;
  const AllStrapiDataCommonBlogSection =
    data.allStrapiDataCommonBlogSection.edges;
  const getAllStrapiCommonBlogSections = AllStrapiCommonBlogSections.filter(
    (node) => node.node.locale === locale
  );
  const getAllStrapiDataCommonBlogSection =
    AllStrapiDataCommonBlogSection.filter(
      (node) => node.node.locale === locale
    );
  const allNews = getAllStrapiCommonBlogSections;
  const list = [...allNews.slice(0, 2)];

  const handleMore = () => {
    const path = ``;
    navigate(`${path}/resource/blog`);
  };
  const handleReadMore = (e) => {
    const path = ``;
    navigate(`${path}/resource/blog/${e}`);
  };
  return (
    <div className="blogs">
      <div className="blogSwap">
        <div className="blogsTitle">
          {" "}
          <h2>{getAllStrapiDataCommonBlogSection[0].node.title}</h2>
        </div>
        <div className="moreBlogs">
          <h2 className="blogsHeading">
            {getAllStrapiDataCommonBlogSection[0].node.heading}
          </h2>
          <div className="opsBtnwrap middelBtn" onClick={handleMore}>
            <a className="opsBtnlink">
              {getAllStrapiDataCommonBlogSection[0].node.btn}
            </a>
          </div>
        </div>
        <div className="blogContent">
          <div className="blogContentLeft">
            <Img
              fixed={list[0].node.blogimg[0]?.localFile.childImageSharp.fixed}
              alt="homepageblog"
              className="blogContentImgLeft"
            ></Img>
            <div className="blogContentDetailsLeft">
              <div className="blogContentTitle">{list[0].node.heading}</div>
              <div className="blogContentDescription">
                {list[0].node.preview}
              </div>
              <button
                className="blogContentBtn"
                onClick={() => handleReadMore(list[0].node.slug)}
              >
                Read More
              </button>
            </div>
          </div>
          <div className="blogContentRight">
            <div className="blogContentDetailsRight">
              <div className="blogContentTitle">{list[1].node.heading}</div>
              <div className="blogContentDescription">
                {list[1].node.preview}
              </div>
              <button
                className="blogContentBtn"
                onClick={() => handleReadMore(list[1].node.slug)}
              >
                Read More
              </button>
            </div>
            <Img
              fixed={list[1].node.blogimg[0].localFile.childImageSharp.fixed}
              alt="homepageblog"
              className="blogContentImgRight" loading="lazy"
            ></Img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageBlog;
