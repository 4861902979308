import React from "react";
import { Link } from "gatsby";

const WhitepaperSection = ({ data }) => {
  return (
    <div className="d-flex justify-content-center  py-5 whitepaper-homesection">
    
      <div className="col-6 px-5 left-container" >
        <h2 className="pb-3">{data.heading}</h2>
        <p className="col-9">{data.description}</p>
      </div>
      <div className="col-3 px-4 py-4 right-container">
        <h3 className="pb-3">{data.boxTitle}</h3>
        <p className="pb-3">{data.boxDescription}</p>
        <div className="opsBtnwrap">
          <Link to={data.btnlink} className="opsBtnlink">
            <p>{data.btntext}</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WhitepaperSection;
