import React from "react";
import OptCampaign from "./opt_campaign";
import MigrationCampaign from "./migration_campaign";

function Campaign({ data, locale }) {
  return (
    <div className="Campaigns">
      <div className="campaingSwap">
        <div className="CampaignsTitle">{data.title}</div>
        <div className="CampaignsHeading">
          {data.heading1}{" "}
          <span className="CampaignsHeadingBlue">{data.heading2}</span>
        </div>
        <div className="CampaignsDescription">{data.description}</div>
        <div className="campaign_tab tabbing first_tab">
          <div className="container-inside">
            <div className="title">
              <div className="CampaignsTitle">{data.sections[0].title}</div>
              <div className="CampaignsHeading">{data.sections[0].heading}</div>
            </div>
            <OptCampaign section={data.sections[0]} btntext={data.btntext} />
          </div>
        </div>

        <div className="migration_tab tabbing">
          <div className="container-inside">
            <div className="title">
              <div className="CampaignsTitle">{data.sections[1].title}</div>
              <div className="CampaignsHeading">{data.sections[1].heading}</div>
            </div>
            <MigrationCampaign
              section={data.sections[1]}
              btntext={data.btntext}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Campaign;
