import React from "react";
import OurappCdsIcon from "../../images/assets/ourappCds-icon.svg";
import OurappIdpIcon from "../../images/assets/ourappIdp-icon.svg";
import OurappSdsIcon from "../../images/assets/ourappSds-icon.svg";
import OurappSqcIcon from "../../images/assets/ourappSqc-icon.svg";

const Ourapproach = ({ data }) => {
  return (
    <div className="ouraprchSec">
      <div className="ourappWrap">
        <div className="ourappWrap-desc">
          <h2 className="smallHeading">{data.ourApproachTitle}</h2>
          <h2 className="secHeading">
            {data.ourApproachHeading1} <span>{data.ourApproachHeading2}</span>
          </h2>
          <p className="ourAppDesc">{data.ourApproachDescription}</p>
        </div>
        <div className="ourappCradwrap">
          <div className="ourAppcol">
            <div className="ourApp--wrap">
              <div className="icon-wrap">
                <OurappIdpIcon />
              </div>

              <p className="ourAppcol--desc">{data.ourApproaches.item1}</p>
            </div>
          </div>
          <div className="ourAppcol">
            <div className="ourApp--wrap">
              <div className="icon-wrap">
                <OurappCdsIcon />
              </div>
              <p className="ourAppcol--desc">{data.ourApproaches.item2}</p>
            </div>
          </div>
          <div className="ourAppcol">
            <div className="ourApp--wrap">
              <div className="icon-wrap">
                {" "}
                <OurappSdsIcon />
              </div>
              <p className="ourAppcol--desc">{data.ourApproaches.item3}</p>
            </div>
          </div>
          <div className="ourAppcol">
            <div className="ourApp--wrap">
              <div className="icon-wrap">
                <OurappSqcIcon />
              </div>
              <p className="ourAppcol--desc">{data.ourApproaches.item4}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ourapproach;
