import React from "react";
import { Link } from "gatsby";
import TextTransition, { presets } from "react-text-transition";
import bgVideoMobile from "../../images/index-mobile.mp4";
import Seo from "../seo";

import bgVideoin from "../../images/index-in-mobile.mp4";
const HeroSection = ({ data, locale,metaTitle,metaDes }) => {
 
  let heroSecDyText = data.title2.split(",");
  const [index, setIndex] = React.useState(0);


  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  return (
    <>
    <Seo title={metaTitle} description={metaDes} lang={locale}/>
    <div className="homeHerosec">
      <video
        id="background-video"
        style={
          locale === "en-IN"
            ? {
              transform: "translate(30%, 0)",
            }
            : {}
        }
        autoPlay
        loop
        muted
      >
        <source
          src={locale === "en-IN" ? bgVideoin : bgVideoMobile}
          type="video/mp4" loading="lazy"
        ></source>
      </video>
      <div className="heroWrap">
        <div className="heroTextwrap">
          <h1 className="m-0">
            {data.title1}
            <span>
              <TextTransition
                text={heroSecDyText[index % heroSecDyText.length]}
                springConfig={presets.wobbly}
                inline={false}
              />
            </span>
          </h1>
          <div className="deswrap">
            <p>
              {data.description1} <b>{data.description2}</b> {data.description3}{" "}
              <b>{data.description4}</b>
            </p>
          </div>

          <div className="opsBtnwrap">
            <Link className="opsBtnlink" to={`/contact-us/`}>
              <p>{data.herobutton}</p>
            </Link>
          </div>
        </div>
        <div className="heroBottom">
          <p>{data.experiencedTeamsLabel}</p>
          <ul>
            {Object.values(data.ExperiencedTeams).map((val, index) => (
              <li key={index}>
                <Link to={`/${val.slug}/`}>{val.label}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
    </>
  );
};

export default HeroSection;
