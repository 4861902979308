import * as React from "react";
import "../styles/index2.scss";
import "../styles/home.scss";

import Layout from "../components/layout";
import SuccessStories from "../components/SuccessStories";
import "../styles/index.scss";
import HomepageBlog from "../components/homepage/blogs";
import "react-tabs/style/react-tabs.css";
import { graphql } from "gatsby";
import { useMemo } from "react";
import HeroSection from "../components/homepage/herosection";
import Ourapproach from "../components/homepage/ourapproach";
import Approach from "../components/homepage/approach";
import Campaign from "../components/homepage/campaign";
import Solutions from "../components/homepage/solutions";
import Partner from "../components/homepage/partner";
import EnquireNow from "../components/EnquireNow";
import SEO from "../components/seo";
import WhitepaperSection from "../components/homepage/whitepaper";
const IndexPage = ({ data }) => {
  const getIndexData = (data) => {
    const values = data.allStrapiIndexpage.edges[0].node;
    return values;
  };
  const getData = useMemo(() => getIndexData(data), [data]);

  return (
    <Layout slug={getData.slug} locale={getData.locale}>
      <SEO
        slug={getData.slug}
        locale={getData.locale}
        title={getData.metaTitle}
        description={getData.metaDes}
      />
      <main>
        <HeroSection
          data={getData.herosection}
          locale={getData.locale}
          metaTitle={getData.metaTitle}
          metaDes={getData.metDes}
        />
        <Ourapproach data={getData.ourapproach} />
        <Approach data={getData.approach} />
        <EnquireNow data={getData.enquireNow} locale={getData.locale} />
        <Solutions data={getData.solutions} locale={getData.locale} />
        <Partner title={getData.partner.title} locale={getData.locale} />
        <WhitepaperSection data={getData.whitepaper} />
        <Campaign
          data={{
            ...getData.campaigns,
            btntext: getData.herosection.herobutton,
          }}
          locale={getData.locale}
        />
        <SuccessStories locale={getData.locale} />
        <HomepageBlog locale={getData.locale} />
      </main>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage($locale: String!) {
    allStrapiIndexpage(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          slug
          locale
          metaTitle
          metaDes
          herosection {
            title1
            title2
            description1
            description2
            description3
            description4
            herobutton
            experiencedTeamsLabel
            ExperiencedTeams {
              item1 {
                label
                slug
              }
              item2 {
                label
                slug
              }
              item3 {
                label
                slug
              }
              item4 {
                label
                slug
              }
              item5 {
                label
                slug
              }
            }
          }
          enquireNow {
            btn
            email
            title
          }
          approach {
            approachTitle
            approachHeading
            approachDescription
            approaches {
              item1 {
                button
                description
                heading
              }
              item2 {
                heading
                description
                button
              }
              item3 {
                heading
                description
                button
              }
              item4 {
                heading
                description
                button
              }
            }
          }
          ourapproach {
            ourApproachTitle
            ourApproachHeading1
            ourApproachHeading2
            ourApproachDescription
            ourApproaches {
              item1
              item2
              item3
              item4
            }
          }
          solutions {
            title
            heading1
            heading2
            solutionsList {
              name
              src
            }
            CTA {
              btn
              des
              heading
            }
          }
          partner {
            title
          }
          whitepaper {
            heading
            description
            boxTitle
            boxDescription
            btntext
            btnlink
          }
          campaigns {
            title
            heading1
            heading2
            description
            sections {
              heading
              sn
              title
              info
              tabs
              tab1 {
                opt1
                opt1description
                opt2
                opt2description
                opt3
                opt3description
                pillars {
                  name
                  options
                }
              }
              tab2 {
                opt1
                opt2
                opt3
                opt4
              }
              tab3 {
                opt3
                opt4
                opt5
                opt5description
                opt4description
                opt3description
                opt2description
                opt1description
                opt1
                opt2
              }
            }
          }
        }
      }
    }
  }
`;
