import React from "react";
import CostaAnalysis from "../../images/assets/costaAnalysis-icon.svg";
import SecurityTight from "../../images/assets/securityTight-icon.svg";
import ProffofCon from "../../images/assets/proffofCon-icon.svg";
import MigstragyIcon from "../../images/assets/migstragy-icon.svg";

const Approach = ({ data }) => {
  return (
    <div className="approachSec">
      <div className="apprsecWrap">
        <div className="apprWrap-desc">
          <h4 className="smallHeading">{data.approachTitle}</h4>
          <h2 className="secHeading">{data.approachHeading}</h2>
          <p className="ourAppDesc">{data.approachDescription}</p>
        </div>
      </div>
      <div className="apprchContainer">
        <div className="row apprCradwrap">
          <div className="apprCrdcol col-md-6 col-12">
            <div className="appr--wrap">
              <div className="icon-wrap">
                <CostaAnalysis />
              </div>

              <div className="apprtextWrap">
                <h2>{data.approaches.item1.heading}</h2>
                <p>{data.approaches.item1.description}</p>
              </div>
            </div>
          </div>
          <div className="apprCrdcol col-md-6 col-12">
            <div className="appr--wrap">
              <div className="icon-wrap">
                <MigstragyIcon />
              </div>
              <div className="apprtextWrap">
                <h2>{data.approaches.item2.heading}</h2>
                <p>{data.approaches.item2.description}</p>
              </div>
            </div>
          </div>
          <div className="apprCrdcol col-md-6 col-12">
            <div className="appr--wrap">
              <div className="icon-wrap">
                <SecurityTight />
              </div>
              <div className="apprtextWrap">
                <h2>{data.approaches.item3.heading}</h2>
                <p>{data.approaches.item3.description}</p>
              </div>
            </div>
          </div>
          <div className="apprCrdcol col-md-6 col-12">
            <div className="appr--wrap">
              <div className="icon-wrap">
                <ProffofCon />
              </div>
              <div className="apprtextWrap">
                <h2>{data.approaches.item4.heading}</h2>
                <p>{data.approaches.item4.description}</p>
                {/* {data.approaches.item4.button} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Approach;
