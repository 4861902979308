import React from "react";
import branas from "../images/branas.svg";
import forlaget from "../images/forlaget.svg";
import politico from "../images/politico.svg";
import lofbergs from "../images/lofbergs.svg";
import branasguppen from "../images/branasguppen.svg";
import migicon from "../images/migicon.svg";
import Eow from "../images/eraofwe.svg";
import opus from "../images/opus.webp";
import savr from "../images/savr.webp";
import silverrail from "../images/silverrail.webp";

import { graphql, useStaticQuery, Link } from "gatsby";
const SuccessStories = ({ locale, partner }) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiSuccessStoryCommon {
        edges {
          node {
            locale
            heading
            description
            description2
            detailcomponent {
              button
              description
              heading
              slug
            }
            title
          }
        }
      }
    }
  `).allStrapiSuccessStoryCommon.edges;

  const filterData = data.filter((node) => node.node.locale === locale);
  const getData = filterData[0].node;
  return (
    <div
      className={`successStories ${partner ? "partnerHotspot" : ""}`}
      id="successstories"
    >
      <div className="successSwap">
        <div className="successStoriesTitle">{getData.title}</div>
        <div className="successStoriesHeading">{getData.heading}</div>
        <div className="successStoriesDescription">{getData.description}</div>
        <div className="successStoriesDescription">{getData.description2}</div>

        <div className="successStoriesImages">
          <div className="inside_stories">
            <div className="success_img">
              <a rel="noopener" href="https://www.branas.se/om-branasgruppen/" target="_blank">
                <img className="successStoriesImg" alt="branas" src={branas} loading="lazy" width="100%" height='100%' />
              </a>
            </div>
            <div className="success_img">
              <a rel="noopener" href="http://www.okforlaget.se/" target="_blank">
                <img
                  className="successStoriesImg"
                  alt="forlaget" loading="lazy"
                  src={forlaget} width="100%" height='100%'
                />
              </a>
            </div>
            <div className="success_img">
              <a rel="noopener" href="https://en.lofbergs.se/" target="_blank">
                <img
                  className="successStoriesImg"
                  alt="politico" loading="lazy"
                  src={politico} width="100%" height='100%'
                />
              </a>
            </div>
            <div className="success_img">
              <a rel="noopener" href="https://www.politico.com/" target="_blank">
                <img
                  className="successStoriesImg"
                  alt="lofbergs" loading="lazy"
                  src={lofbergs} width="100%" height='100%'
                />
              </a>
            </div>
            <div className="success_img">
              <a rel="noopener" href="https://www.eraofwe.com/" target="_blank">
                <img
                  className="successStoriesImg"
                  id="eraofwe" loading="lazy"
                  alt="lofbergs"
                  src={Eow} width="100%" height='100%'
                />
              </a>
            </div>
            <br />
            <br />
            <div className="success_img">
              <a rel="noopener" href="https://www.opus.se/" target="_blank">
                <img
                  className="successStoriesImg"
                  id="opus"
                  alt="Opus" loading="lazy"
                  src={opus} width="100%" height='100%'
                />
              </a>
            </div>
            <div className="success_img">
              <a rel="noopener" href="https://www.savr.com/" target="_blank">
                <img
                  className="successStoriesImg"
                  id="savr"
                  alt="SAVR" loading="lazy"
                  src={savr} width="100%" height='100%'
                />
              </a>
            </div>
            <div className="success_img">
              <a rel="noopener" href="https://www.silverrailtech.com/" target="_blank">
                <img
                  className="successStoriesImg"
                  id="silverrail"
                  alt="SilverRail" width="100%" height='100%'
                  src={silverrail} loading="lazy"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="successStoriesCards">
          <div className="successStoriesCard">
            <div className="story_img">
              <img
                src={migicon}
                className="successStoriesCardImg"
                alt="migicon" loading="lazy" width='44px' height='auto'
              />
            </div>
            <div className="successStoriesCardHeading">
              {getData.detailcomponent[0].heading}
            </div>
            <div className="successStoriesCardDescription">
              {getData.detailcomponent[0].description}
            </div>
            <Link
              to={`/successstories/${decodeURI(
                getData.detailcomponent[0].slug
              )}/`}
            >
              <button className="successStoriesCardBtn pt-3">
                {getData.detailcomponent[0].button}
              </button>
            </Link>
          </div>
          <div className="successStoriesCard">
            <div className="story_img">
              <img
                src={branasguppen}
                className="successStoriesCardImg" width='97' height='auto'
                alt="branasgruppen" loading="lazy"
              />
            </div>
            <div className="successStoriesCardHeading">
              {getData.detailcomponent[1].heading}
            </div>
            <div className="successStoriesCardDescription">
              {getData.detailcomponent[1].description}
            </div>
            <Link
              to={`/successstories/${decodeURI(
                getData.detailcomponent[1].slug
              )}/`}
            >
              <button className="successStoriesCardBtn">
                {getData.detailcomponent[1].button}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessStories;
